import { Toast } from "vant";
/**
 * 时间格式化
 * 根据传入格式,对时间戳进行格式化
 *
 * @param timestamp 时间戳
 * @param fmt 格式化格式
 */
const timeFormat = (timestamp = null, fmt = "yyyy-mm-dd") => {
  // 其他更多是格式化有如下:
  // yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合
  timestamp = parseInt(timestamp);
  // 如果为null,则格式化当前时间
  if (!timestamp) timestamp = Number(new Date());
  // 判断用户输入的时间戳是秒还是毫秒,一般前端js获取的时间戳是毫秒(13位),后端传过来的为秒(10位)
  if (timestamp.toString().length == 10) timestamp *= 1000;
  let date = new Date(timestamp);
  let ret;
  let opt = {
    "y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "h+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "s+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
};

/**
 * 全局唯一标识符
 * 用于生成全局唯一标识符uuid
 */
const uuid = () => {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
};

/**
 * 防抖函数(可用于防止重复提交)
 * 当持续触发事件时，一定时间段内没有再触发事件，事件处理函数才会执行一次，
 * 如果设定时间到来之前，又触发了事件，就重新开始延时。也就是说当一个用户一直触发这个函数，
 * 且每次触发函数的间隔小于既定时间，那么防抖的情况下只会执行一次。
 *
 * @param fn 执行函数
 * @param delay 间隔时间 默认 500
 *
 * 案列
 * event:debounce(function(){},delay)
 */
const debounce = (fn, delay = 500) => {
  let timeout = null;
  return function (...args) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

/**
 * 节流函数
 * 当持续触发事件时，保证在一定时间内只调用一次事件处理函数
 * 小于既定值，函数节流会每隔这个时间调用一次
 *
 * @param fn 执行函数
 * @param delay 间隔时间 默认 1000
 *
 * 案列
 * event:throttle(function(){},delay)
 */
const throttle = (fn, delay = 1000) => {
  let lastCall = 0;
  let throttled = function () {
    const now = new Date().getTime();
    // remaining 不触发下一次函数的剩余时间
    if (now - lastCall < delay && lastCall) return;
    lastCall = now;
    fn.apply(this, arguments);
  };
  return throttled;
};

/**
 * 数组去重
 * 去除数组中重复的数据
 *
 * @param array 去重数组
 *
 * 案例
 * dedupe(array)
 */
const dedupe = (array) => {
  return Array.from(new Set(array));
};

/**
 * 去除字符串空格
 *
 * @param str 要处理的字符串
 * @param type 1：所有空格 2：前后空格 3：前空格 4：后空格 默认为 1
 */
const strTrim = (str, type = 1) => {
  switch (type) {
    case 1:
      return str.replace(/\s+/g, "");
    case 2:
      return str.replace(/(^\s*)|(\s*$)/g, "");
    case 3:
      return str.replace(/(^\s*)/g, "");
    case 4:
      return str.replace(/(\s*$)/g, "");
    default:
      return str;
  }
};

/**
 *隐藏中间4位数的手机号码
 *
 * @param val 要隐藏的手机号
 */
const hidePhone = (val) => {
  let tel = JSON.stringify(val);
  let reg = /(\d{3})\d{4}(\d{4})/;
  tel = tel.replace(reg, "$1****$2");
  return tel;
};

/**
 * 小数点后 四舍五入自动补两个零
 *
 * @param val 要处理的数值
 */
const amountFillZero = (val) => {
  val = Math.round(parseFloat(val) * 100) / 100;
  let dot = val.toString().split(".");
  if (dot.length === 1) {
    val = val.toString() + ".00";
  } else if (dot.length > 1) {
    if (dot[1].length < 2) {
      val = val.toString() + "0";
    }
  }
  return val;
};
/**
 * 复制文本
 *
 * @param value 要复制的值
 */
const copyTextNoInput = (value) => {
  if (document.execCommand("copy")) {
    const aux = document.createElement("input");
    aux.setAttribute("value", value);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    // that.$message.success('复制成功')
    Toast({
      type: "text",
      message: "复制成功",
      forbidClick: true,
    });
  } else if (
    navigator.clipboard &&
    typeof navigator.clipboard.writeText === "function"
  ) {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        Toast({
          type: "text",
          message: "复制成功",
          forbidClick: true,
        });
      })
      .catch((err) => {
        Toast({
          type: "text",
          message: "复制失败",
          forbidClick: true,
        });
      });
  }
};

/**
 * 拨打电话
 *
 * @param phoneNumber 要拨打的电话
 */
const diaiPhone = (phoneNumber) => {
  window.location.href = "tel://" + phoneNumber;
};

/**
 * 金额转换中文
 *
 * @param money 金额
 * @param num 要转多少
 * @param type 文字
 */
const formatNum = (money, num = 10000) => {
  money = Number(money);
  if (money == 0) {
    return money;
  } else {
    return (money / num).toFixed(1);
  }
};

const urlToCanvas = (url) => {
  return new Promise((resolve) => {
    var image = new Image();
    // 先设置图片跨域属性
    image.crossOrigin = "Anonymous";
    //  再给image赋值src属性，先后顺序不能颠倒
    image.src = url;
    image.onload = function () {
      var canvas = document.createElement("CANVAS");
      // 设置canvas宽高等于图片实际宽高
      canvas.width = image.width;
      canvas.height = image.height;
      canvas.getContext("2d").drawImage(image, 0, 0);
      let dataURL = canvas.toDataURL("image/jpeg"); // toDataUrl可以接收2个参数，参数一：图片类型，参数二： 图片质量0-1（不传默认为0.92）
      resolve(dataURL);
    };
    image.onerror = () => {
      resolve({ message: "相片处理失败" });
    };
  });
};

/**
 * 时间转换
 *
 * @param date 传入时间
 * @param type 返回类型
 * @param zeroFillFla 是否数字补零，默认补零
 *
 *
 * 如：formatPast("2023-02-01 09:32:01", "YYYY-MM-DD", false)
 */
const formatPast = (date, type = "X个月前", zeroFillFlag = true) => {
  // 定义countTime变量，用于存储计算后的数据
  let countTime;
  // 获取当前时间戳
  let time = new Date().getTime();
  // 转换传入参数为时间戳
  let afferentTime = new Date(date).getTime();
  // 当前时间戳 - 传入时间戳
  time = Number.parseInt(`${time - afferentTime}`);
  if (time < 10000) {
    // 10秒内
    return "刚刚";
  } else if (time < 60000 && time >= 10000) {
    // 超过10秒少于1分钟内
    countTime = Math.floor(time / 1000);
    return `${countTime}秒前`;
  } else if (time < 3600000 && time >= 60000) {
    // 超过1分钟少于1小时
    countTime = Math.floor(time / 60000);
    return `${countTime}分钟前`;
  } else if (time < 86400000 && time >= 3600000) {
    // 超过1小时少于24小时
    countTime = Math.floor(time / 3600000);
    return `${countTime}小时前`;
  } else if (time >= 86400000 && (type == "X天前" || type == "X个月前")) {
    countTime = Math.floor(time / 86400000);
    // 如果大于等于30天 且传入格式为"X个月前"
    if (countTime >= 30 && type == "X个月前") {
      return `${Math.floor(countTime / 30)}个月前`;
    }
    // 否则展示'X天前'
    return `${countTime}天前`;
  } else {
    // 一天（24小时）以上 按传入格式参数显示不同格式
    // 数字补零
    let Y = new Date(date).getFullYear();
    let M = new Date(date).getMonth() + 1;
    let zeroFillM = M > 9 ? M : "0" + M;
    let D = new Date(date).getDate();
    let zeroFillD = D > 9 ? D : "0" + D;
    if (type == "YYYY-MM-DD") {
      return zeroFillFlag ? `${Y}-${zeroFillM}-${zeroFillD}` : `${Y}-${M}-${D}`;
    } else if (type == "YYYY/MM/DD") {
      return zeroFillFlag ? `${Y}/${zeroFillM}/${zeroFillD}` : `${Y}/${M}/${D}`;
    }
    if (type == "YYYY年MM月DD日") {
      return zeroFillFlag
        ? `${Y}年${zeroFillM}月${zeroFillD}日`
        : `${Y}年${M}月${D}日`;
    }
    if (type == "YYYY.MM.DD") {
      return zeroFillFlag ? `${Y}.${zeroFillM}.${zeroFillD}` : `${Y}.${M}.${D}`;
    }
    if (type == "MM月DD日") {
      return zeroFillFlag ? `${zeroFillM}月${zeroFillD}日` : `${M}月${D}日`;
    }
  }
};

export {
  timeFormat,
  uuid,
  debounce,
  throttle,
  dedupe,
  strTrim,
  hidePhone,
  amountFillZero,
  copyTextNoInput,
  diaiPhone,
  formatNum,
  urlToCanvas,
  formatPast,
};
