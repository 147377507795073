import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tabList: [
      {
        pagePath: "/home",
        text: "首页",
        iconPath: require("../assets/tabbar/index.png"),
        selectedIconPath: require("../assets/tabbar/index-active.png"),
      },
      {
        pagePath: "/personal",
        text: "个人中心",
        iconPath: require("../assets/tabbar/user.png"),
        selectedIconPath: require("../assets/tabbar/user-active.png"),
      },
    ],
    userIdentity: {
      name: "用户",
    },
    posterBase: "",
  },
  getters: {},
  mutations: {
    setUserIdentity(state, payload) {
      state.userIdentity = payload;
    },
    setPosterBase(state, payload) {
      state.posterBase = payload;
    },
  },
  actions: {},
  modules: {},
});
