import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Home from "../views/index/home";
import Index from "../views/index/index";

// 分享中心
const Share = () => import("../views/share/share");
// 海报
const Poster = () => import("../views/share/poster");
// 个人中心
const User = () => import("../views/user/user");
// 登录
const Login = () => import("../views/login/login");
// 修改密码
const Change = () => import("../views/login/change");
// 编辑信息
const Edit = () => import("../views/user/edit");
// 启动页
const Activate = () => import("../views/login/activate");
// 客户扫描首页
const Custom = () => import("../views/scan/custom");
// 客户扫描个人信息
const CustomInfo = () => import("../views/scan/customInfo");
// 客户扫描额度倒计时
const CreditLimit = () => import("../views/scan/creditLimit");
// 客户扫描额度倒计时
const Record = () => import("../views/index/record");

// 新的
// 个人中心
const Personal = () => import("../views/user/personal");
// 填写资料
const Information = () => import("../views/order/information");
// 文章
const Article = () => import("../views/index/article");
// 服务
const Service = () => import("../views/index/service");
// 下级列表
const List = () => import("../views/index/list");
// 统计中心
const Statistics = () => import("../views/index/statistics");
// 海报中心
const Posters = () => import("../views/user/poster");
// 待跟进列表页面
const Wait = () => import("../views/statistics/wait");

// 数据可视化大屏
const FineVis = () => import("../views/fineVis/index");

const routes = [
  {
    path: "/",
    redirect: "/activate",
    meta: {
      title: "安惠信",
      needLogin: false, // 是否需要登录
    },
  },
  {
    path: "/activate",
    name: "Activate",
    meta: {
      title: "安惠信",
      needLogin: false, // 是否需要登录
    },
    component: Activate,
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
      needLogin: false, // 是否需要登录
    },
    component: Login,
  },
  {
    path: "/change",
    name: "Change",
    meta: {
      title: "修改密码",
      needLogin: true, // 是否需要登录
    },
    component: Change,
  },
  {
    path: "/index",
    name: "Index",
    meta: {
      title: "安惠信",
      needLogin: true, // 是否需要登录
    },
    component: Index,
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "安惠信",
      needLogin: false, // 是否需要登录
    },
    component: Home,
  },
  {
    path: "/share",
    name: "Share",
    meta: {
      title: "分享中心",
      needLogin: true, // 是否需要登录
    },
    component: Share,
  },
  {
    path: "/poster",
    name: "Poster",
    meta: {
      title: "海报",
      needLogin: true, // 是否需要登录
    },
    component: Poster,
  },
  {
    path: "/user",
    name: "User",
    meta: {
      title: "个人中心",
      needLogin: true, // 是否需要登录
    },
    component: User,
  },
  {
    path: "/edit",
    name: "Edit",
    meta: {
      title: "编辑",
      needLogin: true, // 是否需要登录
    },
    component: Edit,
  },
  {
    path: "/custom",
    name: "Custom",
    meta: {
      title: "安惠信",
      needLogin: false, // 是否需要登录
    },
    component: Custom,
  },
  {
    path: "/customInfo",
    name: "CustomInfo",
    meta: {
      title: "安惠信",
      needLogin: false, // 是否需要登录
    },
    component: CustomInfo,
  },
  {
    path: "/creditLimit",
    name: "CreditLimit",
    meta: {
      title: "安惠信",
      needLogin: false, // 是否需要登录
    },
    component: CreditLimit,
  },
  {
    path: "/record",
    name: "Record",
    meta: {
      title: "安惠信",
      needLogin: true, // 是否需要登录
    },
    component: Record,
  },
  {
    path: "/personal",
    name: "Personal",
    meta: {
      title: "个人中心",
      needLogin: true, // 是否需要登录
    },
    component: Personal,
  },
  {
    path: "/information",
    name: "Information",
    meta: {
      title: "填写资料",
      needLogin: false, // 是否需要登录
    },
    component: Information,
  },
  {
    path: "/list",
    name: "List",
    meta: {
      title: "下级列表",
      needLogin: true, // 是否需要登录
    },
    component: List,
  },
  {
    path: "/statistics",
    name: "Statistics",
    meta: {
      title: "统计中心",
      needLogin: true, // 是否需要登录
    },
    component: Statistics,
  },
  {
    path: "/wait",
    name: "Wait",
    meta: {
      title: "安慧信",
      needLogin: true, // 是否需要登录
    },
    component: Wait,
  },
  {
    path: "/posters",
    name: "Posters",
    meta: {
      title: "文案中心",
      needLogin: true, // 是否需要登录
    },
    component: Posters,
  },
  {
    path: "/article",
    name: "Article",
    meta: {
      title: "每日资讯",
      needLogin: false, // 是否需要登录
    },
    component: Article,
  },
  {
    path: "/service",
    name: "Service",
    meta: {
      title: "平台服务",
      needLogin: false, // 是否需要登录
    },
    component: Service,
  },
  {
    path: "/fineVis",
    name: "FineVis",
    meta: {
      title: "安惠信实时数据大屏",
      needLogin: false, // 是否需要登录
    },
    component: FineVis,
  },
  { path: "*", redirect: "/activate", hidden: false },
];

const router = new VueRouter({
  mode: "hash",
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path != from.path) {
    // 是否需要登录
    if (to.meta.needLogin) {
      if (localStorage.getItem("token")) {
        // 设置标题
        document.title = to.meta.title;
        next();
      } else {
        next("/login");
        // next();
      }
    } else {
      next();
    }
  }
});

export default router;
