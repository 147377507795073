<template>
  <div class="indexs">
    <div class="echarts">
      <div class="slogan">
        <img src="../../assets/page/slogan.png" />
      </div>
      <template v-if="active == 0">
        <div class="times" @click="aloneShow = true">
          <van-icon name="clock-o" style="margin-right: 5px;" size="14" />{{ dateObj ? dateObj : '请选择时间' }}
        </div>
      </template>

      <template v-if="active == 1">
        <div class="times" @click="calendarShow = true">
          <van-icon name="clock-o" style="margin-right: 5px;" size="14" />{{ dateList.length != 0 ? dateList[0] + ' 至 ' +
            dateList[1] : '请选择时间' }}
        </div>
      </template>

      <div class="chart">
        <div class="boxs" @click="goUserList">
          <p>{{ statis.add_scan }}</p>
          <div>今日新增扫码客户</div>
        </div>
        <div class="boxs">
          <p>{{ statis.add_below_scan }}</p>
          <div>今日下级新增扫码客户</div>
        </div>
        <div class="boxs">
          <p>{{ statis.add_below_scan + statis.add_scan }}</p>
          <div>今日总计新增扫码客户</div>
        </div>
        <div class="boxs">
          <p>{{ statis.unfollow }}</p>
          <div>今日未跟进客户</div>
        </div>
        <div class="boxs">
          <p>{{ statis.following }}</p>
          <div>今日已跟进客户</div>
        </div>
        <div class="boxs">
          <p>{{ statis.follow_wait }}</p>
          <div>今日待跟进客户</div>
        </div>
      </div>
    </div>

    <van-tabs v-model="active" sticky title-active-color="#2690F2" color="#2690F2" @click="setTab">
      <van-tab v-for="(item, index) in tabList" :key="index" :title="item.title">
        <div class="list">
          <div class="titleList">
            <div><van-icon name="cashier-o" size="20" color="#2690F2" style="margin-right: 5px;" /><span>客户列表</span></div>
            <div @click="showStatus = true">状态 ({{ defaultStatus.title }})<van-icon name="arrow-down" size="15"
                style="margin-left: 5px;" /></div>
          </div>
          <van-divider />

          <!-- 下拉刷新 -->
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh" success-text="刷新成功">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getLoadList"
              :immediate-check="false">
              <template v-if="list.length > 0">
                <div v-for="(item, index) in list" :key="item.id" @click="getDetails(item, index)">
                  <orderList :item="item" ref="orderList"></orderList>
                </div>
              </template>

              <template v-else>
                <van-empty image="error" />
              </template>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>

    <!-- 客户状态 -->
    <van-popup v-model="showStatus" position="bottom">
      <van-picker title="客户状态" show-toolbar :columns="statusList" @confirm="getStatus" @cancel="showStatus = false"
        value-key="title" :default-index="defaultStatus.index" />
    </van-popup>

    <!-- 单个日期 -->
    <van-calendar v-model="aloneShow" @confirm="onAloneConfirm" show-mark color="#2690F2" :minDate="minDate"
      :maxDate="maxDate" />

    <!-- 多个日期 -->
    <van-calendar v-model="calendarShow" type="range" @confirm="onConfirm" show-mark color="#2690F2" :minDate="minDate"
      :maxDate="maxDate" />

    <van-action-sheet v-model="phoneShow" title="拨打电话">
      <div class="popup">
        <div class="phone">{{ mobile }}</div>
        <div class="bth">
          <van-button color="#2690F2" round @click="copyTextNoInput(mobile)">
            复 制
          </van-button>
          <van-button color="#FF5151" round @click="diaiPhone(mobile)">
            拨 打
          </van-button>
        </div>
      </div>
    </van-action-sheet>

    <!-- 跟进记录 -->
    <van-popup v-model="showPlan" position="bottom" closeable>
      <div class="plan">
        <div class="titles"><span>{{ details.name }}</span>的跟进记录</div>
        <div class="steps">
          <div class="one" v-for="i in detailsList" :key="i.id">
            <div class="left">
              <van-icon name="checked" color="rgba(0, 133, 255, 1)" size="20" />
            </div>
            <div class="right">
              <!-- <div class="biaoti">【{{ userIdentity.name != '用户' ? i.customer_status_text : i.apply_status_text }}】</div> -->
              <div class="biaoti">【{{ i.customer_status_text + ' / ' + i.apply_status_text }}】</div>
              <template v-if="userInfo.is_company">
                <div><b>客户资质：</b>房产一套</div>
                <div><b>登记情况：</b>{{ i.content }}</div>
              </template>
              <template v-else>
                <div>{{ i.content }}</div>
              </template>
              <div> 由 <span>{{ i.user.nickname }}</span> 于 <span>{{ timeFormat(i.createtime, 'yyyy-mm-dd hh:MM') }}</span>
                进行跟进
              </div>
            </div>
          </div>
        </div>

        <van-button round color="linear-gradient( #008AFF, #00B2FF)" block type="info" native-type="submit" size="small"
          class="planBth" @click="showRecord = true" v-if="userIdentity.name == '员工'">添加记录</van-button>
      </div>
    </van-popup>

    <!-- 添加记录 -->
    <van-popup v-model="showRecord" round>
      <div class="message" :style="addForm.customer_status == 3 ? 'height:270px' : 'height:550px'">
        <div class="boti"><van-icon name="description" color="#57A9F8" style="margin-right: 5px;" />跟进记录登记</div>

        <van-form class="form" @submit="showRecommend = false" label-width="6em">
          <van-field readonly clickable name="picker" label="状态" placeholder="请选择状态" @click="customerShow = true"
            v-model="addForm.customer_text" />
          <template v-if="addForm.customer_status != 3">
            <van-field type="textarea" v-model="addForm.qualification" :autosize="{ maxHeight: 135, minHeight: 135 }"
              label="客户资质" placeholder="请输入客户资质" />
            <van-field :autosize="{ maxHeight: 135, minHeight: 135 }" label="登记情况" type="textarea" placeholder="请输入登记情况"
              v-model="addForm.content" />
            <van-field readonly clickable name="picker" label="下次跟进时间" placeholder="可选择下次跟进提醒时间"
              @click="calendarShow = true" v-model="addForm.calendar" />
          </template>
          <template v-else>
            <van-field label="实际放款金额" placeholder="请输入登记情况" v-model="addForm.money" type="digit" />
            <van-field readonly clickable name="picker" label="还款日提醒" placeholder="请选择还款日提醒" @click="calendarShow = true"
              v-model="addForm.repayment" />
          </template>

          <van-button round color="linear-gradient( #008AFF, #00B2FF)" block type="info" native-type="submit" size="small"
            class="formBth" @click="addRecord">添加记录</van-button>
        </van-form>
      </div>
    </van-popup>

    <!-- 记录状态 -->
    <van-popup v-model="customerShow" position="bottom">
      <van-picker title="跟进状态" show-toolbar :columns="customerList" @confirm="getCustomer" @cancel="customerShow = false"
        value-key="title" />
    </van-popup>

    <!-- 下次跟进时间 -->
    <van-calendar v-model="calendarShow" @confirm="getCalendarConfirm" color="#1989fa" />

    <Tabbar></Tabbar>
  </div>
</template>
<script>
import Tabbar from '../../components/tabbar.vue'
import orderList from '@/components/orderList.vue';
import { diaiPhone, copyTextNoInput, timeFormat, formatNum } from '../../common/util.js'
import { Toast } from 'vant';
import { mapState } from 'vuex';
export default {
  name: 'Index',
  data() {
    return {
      diaiPhone,
      copyTextNoInput,
      formatNum,
      timeFormat,
      active: 0,
      tabList: [
        {
          title: '客户列表',
          type: 1
        },
        {
          title: '待首页拜访客户',
          type: 2
        },
        {
          title: '预约提醒列表',
          type: 3
        }
      ],
      dateObj: timeFormat(new Date()),
      dateList: [],
      minDate: new Date(2023, 1, 1),
      maxDate: new Date(),
      // 时间
      countdown: [],
      countdownStr: 24,
      // 图表
      statis: [],
      // 下拉刷新
      loading: false,
      finished: false,
      refreshing: false,
      phoneShow: false,
      calendarShow: false,
      aloneShow: false,
      list: [],
      page: 1,
      last_page: 1,
      mobile: "",
      showStatus: false,
      defaultStatus: {
        index: 0,
        type: '',
        title: '全部'
      },
      // 客户状态
      statusList: [
        {
          title: '全部',
          type: ''
        },
        {
          title: '未跟进',
          type: 0
        },
        {
          title: '跟进中',
          type: 1
        },
        {
          title: '已邀约',
          type: 2
        },
        {
          title: '已成交',
          type: 3
        },
        {
          title: '无效客户',
          type: -1
        }
      ],
      // 跟进进度
      details: {},
      detailsList: [],
      details_page: 1,
      details_last_page: 1,
      details_per_page: 15,
      showPlan: false,
      detailsIndex: 0,
      // 添加跟进记录
      showRecord: false,
      // 添加记录表单
      addForm: {
        content: '',
        qualification: '',
        customer_status: '',
        customer_text: '',
        calendar: '',
        calendar_num: '',
        // 成交出现
        money: 0,
        repayment: '',
        repayment_num: ''
      },
      customerShow: false,
      calendarShow: false,
      // 跟进状态
      customerList: [
        {
          title: '未跟进',
          type: 0
        },
        {
          title: '跟进中',
          type: 1
        },
        {
          title: '已邀约',
          type: 2
        },
        {
          title: '已成交',
          type: 3
        },
        {
          title: '无效客户',
          type: -1
        }
      ],
    }
  },
  components: {
    Tabbar,
    orderList
  },
  mounted() {
    this.getIdentity()
    this.init()
    this.getOrderList()
  },
  computed: {
    ...mapState(['userIdentity'])
  },
  methods: {
    // 身份查询
    getIdentity() {
      this.$http.get('user/identity').then(res => {
        this.$store.commit('setUserIdentity', res.data)
        // if ()
      }).catch(err => {
        console.log(err);
      })
    },
    init() {
      this.$http.post('customer/index', {
        date: this.active ? this.dateList.join(' - ') : this.dateObj
      }).then(res => {
        this.countdown = res.data.countdown;
        this.statis = res.data.statistics;
        this.userList = res.data.list.data;
        this.last_page = res.data.list.last_page;
      }).catch(err => {
        console.log(err);
      })
    },
    // 获取状态
    getStatus(e, index) {
      this.defaultStatus = {
        index: index,
        type: e.type,
        title: e.title
      }
      this.showStatus = false
      this.list = []
      this.page = 1
      this.last_page = 1
      this.finished = false
      this.getOrderList()
    },
    getList() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '查询中',
      });
      this.$http.post('customer/list', {
        page: this.page,
        date: this.active ? this.dateList.join(' - ') : this.dateObj,
        countdown: this.countdownStr
      }).then(res => {
        let Arr = this.userList;
        this.userList = []
        this.userList = [...Arr, ...res.data.data];
        this.last_page = res.data.last_page;
        this.loading = false;
        Toast.clear();
      }).catch(err => {
        console.log(err);
      })
    },
    setTab() {
      //昨天的时间
      let day = new Date();
      day.setTime(day.getTime() - 24 * 60 * 60 * 1000);
      let s1 = day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate();
      if (this.active == 0) {
        this.dateObj = timeFormat(day)
      } else {
        this.dateList = [s1, timeFormat(day)]
      }
      this.userList = []
      this.page = 1
      this.last_page = 1
      this.init()
    },
    getLoadList() {
      if (this.page == this.last_page) {
        this.loading = false;
        this.finished = true;
      } else {
        // this.loading = false;
        this.page++
        setTimeout(() => {
          this.getList()
        }, 500)
      }
    },
    goRecord(item) {
      this.$router.push({
        name: 'Record',
        query: { id: item.id }
        // params: { id: 5 }
      })
    },
    onConfirm(date) {
      const [start, end] = date;
      this.dateList = [timeFormat(Date.parse(start)), timeFormat(Date.parse(end))]
      this.calendarShow = false
      this.init()
    },
    onAloneConfirm(date) {
      this.dateObj = timeFormat(Date.parse(date))
      this.aloneShow = false
      this.init()
    },
    getPhone(mobile) {
      this.phoneShow = true
      this.mobile = mobile
    },
    goUserList(index) {
      console.log(index);
      this.$router.push('List')
    },
    // 获取订单
    getOrderList() {
      let obj = null
      if (this.defaultStatus.type == '') {
        obj = {
          page: this.page
        }
      } else {
        obj = {
          status: this.defaultStatus.type,
          page: this.page
        }
      }
      this.$http.post('customer/list', obj).then(res => {
        let Arr = this.list
        this.list = []
        this.list = [...Arr, ...res.data.data]
        this.last_page = res.data.last_page
        this.per_page = res.data.per_page
        this.loading = false;
        this.refreshing = false
      }).catch(err => {
        console.log(err);
      })
    },
    // 下拉刷新
    onRefresh() {
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      setTimeout(() => {
        this.list = []
        this.page = 1
        this.last_page = 1
        this.getOrderList()
      }, 1500)
    },
    // 获取跟进记录
    getDetails(e, index) {
      this.detailsList = []
      this.details = e
      this.detailsIndex = index + 1
      this.getDetailsList()
    },
    getDetailsList() {
      this.$http.post('customer_follow/list', {
        customer_id: this.details.id
      }).then(res => {
        let Arr = this.detailsList
        this.detailsList = []
        this.detailsList = [...Arr, ...res.data.data]
        this.details_last_page = res.data.last_page
        this.showPlan = true
      }).catch(err => {
        console.log(err);
      })
    },
    // 执行跟进操作时 更新数组
    updataList() {
      let index = Math.ceil(this.detailsIndex / this.per_page)
      let obj = null
      if (this.defaultStatus.type == '') {
        obj = {
          page: index
        }
      } else {
        obj = {
          status: this.defaultStatus.type,
          page: index
        }
      }
      this.$http.post('customer/list', obj).then(res => {
        this.list.splice((index - 1) * this.per_page, this.per_page, ...res.data.data)
      }).catch(err => {
        console.log(err);
      })
    },
    // 跟进记录状态
    getCustomer(e, index) {
      this.addForm.customer_status = e.type
      this.addForm.customer_text = e.title
      this.customerShow = false
    },
    getCalendarConfirm(date) {
      console.log(date);
      if (this.addForm.customer_status == 3) {
        this.addForm.repayment = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
        this.addForm.repayment_num = date.getTime() / 1000;
      } else {
        this.addForm.calendar = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
        this.addForm.calendar_num = date.getTime() / 1000;
      }
      this.calendarShow = false
    },
    // 添加记录
    addRecord() {
      let { content, qualification, customer_status, calendar_num, customer_text } = this.addForm
      if (content == '' || qualification == '' || customer_text == '') {
        Toast('请认真填写资料');
        return false
      }
      this.$http.post('customer_follow/add', {
        customer_id: this.details.id,
        content: content,
        qualification: qualification,
        customer_status: customer_status,
        followtime: calendar_num
      }).then(res => {
        console.log(res);
        this.showRecord = false
        this.showPlan = false
        this.addForm = {
          content: '',
          qualification: '',
          customer_status: '',
          customer_text: ''
        }
        this.updataList()
        Toast(res.msg);
      }).catch(err => {
        console.log(err);
      })
    },
  }
}
</script>
<style lang='scss' scoped>
.indexs {
  box-sizing: border-box;
  background: #EDF4FF;
}

.echarts {
  background-image: url('../../assets/page/indexBj.png');
  height: 400px;
  width: 100vw;
  box-sizing: border-box;
  background-size: 400px 100%;

  .slogan {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;

    img {
      width: 220px;
      height: 32px;
    }
  }

  .times {
    background: white;
    font-size: 12px;
    line-height: 20px;
    color: black;
    border-radius: 33px;
    padding: 5px 15px;
    display: inline-block;
    margin-top: 13px;
    margin-left: 16px;
  }

  .chart {
    height: 300px;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2%;

    .boxs {
      width: 44%;
      height: 72px;
      text-align: center;
      border-radius: 10px;
      background: white;
      margin: 4px 8px;

      p {
        font-size: 32px;
        line-height: 52px;
        font-weight: 700;
        margin: 0;
      }

      div {
        font-size: 13px;
        line-height: 13px;
        font-weight: 650;
      }
    }
  }
}

.list {
  margin: 8px;
  box-sizing: border-box;
  background: white;
  border-radius: 14px;
  padding: 8px;
}

.popup {
  text-align: center;
  box-sizing: border-box;

  .phone {
    font-size: 32px;
    font-weight: 700;
    padding: 20px 0 30px;
    line-height: 38px;
  }

  .bth {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 40px;

    .van-button {
      width: 130px;
      font-size: 16px;
    }
  }
}

.titleList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  // padding-bottom: 15px;

  div {
    display: flex;
    align-items: center;
  }
}
</style>