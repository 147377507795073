import axios from "axios";
import { Toast } from "vant";
import { BASE_URL, TIME_OUT } from "../../config.js";

export function request(config) {
  // console.log("config", config);

  const service = axios.create({
    baseURL: BASE_URL, //配置公共接口
    // baseURL: "/api", //配置公共接口

    timeout: TIME_OUT, //配置请求超时时间
  });

  // 请求拦截器
  service.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
      config.headers["company"] = 0;
    }
    // console.log("拦截请求", config);

    return config;
  });

  // 响应拦截器
  service.interceptors.response.use(
    (res) => {
      // console.log("回调data", res.data);

      if (res.status === 200) {
        if (res.data.code != 1) {
          Toast({
            type: "text",
            message: res.data.msg,
            forbidClick: true,
          });
        } else {
          return Promise.resolve(res.data);
        }
      } else if (res.status === 401) {
        Toast({
          type: "text",
          message: "未授权，请重新登录",
          forbidClick: true,
        });
        this.$router.push("login");
      } else {
        Toast({
          type: "text",
          message: "请求失败",
          forbidClick: true,
        });
      }
      // 请求出现错误时回调
      return Promise.reject(res.data);
    },
    (err) => {
      // console.log("回调错误数据", err);
      Toast({
        type: "text",
        message: err.message,
        forbidClick: true,
      });
      return Promise.reject(err.response.data);
    }
  );
  return service(config);
}
