<template>
  <div>
    <van-tabbar v-model="active" z-index="100" active-color="#2690F2" inactive-color="#000000" placeholder
      @change="onChange">
      <van-tabbar-item v-for="(item, index) in list" :key="index" :name="item.pagePath">
        <span>{{ item.text }}</span>
        <template #icon="props">
          <img :src="props.active ? item.selectedIconPath : item.iconPath" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'Tabbar',
  data() {
    return {
      // list: [
      // {
      //   pagePath: "/index",
      //   text: "首页",
      //   iconPath: require('../assets/tabbar/index.png'),
      //   selectedIconPath: require('../assets/tabbar/index-active.png')
      // },
      // {
      //   pagePath: "/share",
      //   text: "业务中心",
      //   iconPath: require('../assets/tabbar/share.png'),
      //   selectedIconPath: require('../assets/tabbar/share-active.png')
      // },
      // {
      //   pagePath: "/user",
      //   text: "个人中心",
      //   iconPath: require('../assets/tabbar/user.png'),
      //   selectedIconPath: require('../assets/tabbar/user-active.png')
      // }
      //   {
      //     pagePath: "/home",
      //     text: "首页",
      //     iconPath: require('../assets/tabbar/index.png'),
      //     selectedIconPath: require('../assets/tabbar/index-active.png')
      //   },
      //   {
      //     pagePath: "/personal",
      //     text: "个人中心",
      //     iconPath: require('../assets/tabbar/user.png'),
      //     selectedIconPath: require('../assets/tabbar/user-active.png')
      //   }
      // ],
      active: this.$route.matched[0].path
    }
  },
  computed: {
    ...mapState(['tabList', 'userIdentity']),
    list() {
      if (this.userIdentity.name == '员工') {
        return [
          {
            pagePath: "/statistics",
            text: "首页",
            iconPath: require("../assets/tabbar/index.png"),
            selectedIconPath: require("../assets/tabbar/index-active.png"),
          },
          {
            pagePath: "/personal",
            text: "个人中心",
            iconPath: require("../assets/tabbar/user.png"),
            selectedIconPath: require("../assets/tabbar/user-active.png"),
          }
        ]
      } else {
        return [
          {
            pagePath: "/home",
            text: "首页",
            iconPath: require("../assets/tabbar/index.png"),
            selectedIconPath: require("../assets/tabbar/index-active.png"),
          },
          {
            pagePath: "/personal",
            text: "个人中心",
            iconPath: require("../assets/tabbar/user.png"),
            selectedIconPath: require("../assets/tabbar/user-active.png"),
          }
        ]
      }
    }
  },
  methods: {
    onChange(e) {
      if (e != this.$route.path) {
        this.$router.push(e).catch(err => {
          console.log(err);
        })
      }
    }
  }
}
</script>
<style lang='scss' scoped></style>