<template>
  <div class="Home">
    <van-swipe class="swiper" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in imgList" :key="index">
        <img v-lazy="item.image" width="100%" height="100%" />
      </van-swipe-item>
    </van-swipe>
    <van-notice-bar class="bar" left-icon="volume-o" :scrollable="true" color="#363636" background="#fff"
      :text="noticle.content" />
    <div class="static">
      <van-grid>
        <van-grid-item>
          <div class="num">{{ numberList[0] }}</div>
          <div class="status">累计登记</div>
        </van-grid-item>
        <van-grid-item>
          <div class="num">{{ numberList[1] }}</div>
          <div class="status">累计解决</div>
        </van-grid-item>
        <van-grid-item>
          <div class="num">{{ numberList[2] }}</div>
          <div class="status">服务企业</div>
        </van-grid-item>
        <van-grid-item>
          <div class="num">{{ numberList[3] }}</div>
          <div class="status">服务业务</div>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="business main_item">
      <div class="title">
        业务咨询<span>点击测额</span>
      </div>
      <div class="content">
        <van-grid :gutter="10" :column-num="3" :clickable="true">
          <van-grid-item v-for="(item, index) in businessList" :key="index" @click="getGridBusiness(item)"
            class="gridBox">
            <img :src="item.image" />
            <template v-if="item.title">
              <div class="name" v-if="item.title.length > 1" style="font-size: 18px;">{{ item.title[1] }}</div>
              <div class="name" v-if="item.title.length > 0" style="font-size: 12px;">{{ item.title[0] }}</div>
            </template>
            <template v-if="item.type == 'more'">
              <div class="name" style="font-size: 18px;">{{ item.more }}</div>
            </template>
            <div class="num" v-if="item.amount_range">{{ item.amount_range }}</div>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <!-- <div class="finance main_item">
      <div class="title">
        平台服务<span>提供服务</span>
      </div>
      <div class="content">
        <van-tabs class="tabs" v-model="financeActive" color="#2690F2">
          <van-tab v-for="(item, index) in financeTabs" :key="index" :title="item.title">
            <div class="list">
              <div class="list_item" v-for="(item, index) in financeList" :key="index">
                <img class="pic" :src="item.img" alt="">
                <div class="cont">
                  <div class="title van-ellipsis">{{ item.title }}</div>
                  <div class="desc van-ellipsis">{{ item.desc }}</div>
                  <div class="value_time">
                    <div class="value">
                      <span class="v van-ellipsis">{{ item.maxQuato }}</span>
                      <span class="t">最高额度</span>
                    </div>
                    <div class="time">
                      <span class="v van-ellipsis">{{ item.maxTime }}</span>
                      <span class="t">最长期限</span>
                    </div>
                  </div>
                  <div class="rate">
                    <span class="t">最低年华率</span>
                    <span class="v">{{ item.minRate }}</span>
                  </div>
                  <div class="rate">
                    <span class="t">还款方式</span>
                    <span class="v">{{ item.backType.join('/') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div> -->
    <div class="finance main_item">
      <div class="title">
        平台服务<span>提供服务</span>
      </div>
      <div class="content">
        <!-- <van-tabs class="tabs" v-model="financeActive" color="#2690F2">
          <van-tab v-for="(item, index) in lifeTabs" :key="index" :title="item.title"> -->
        <div class="list">
          <div class="list_item" v-for="(item, index) in platform_services" :key="index" @click="goService(item)">
            <img class="pic" :src="item.image" alt="">
            <div class="cont">
              <div class="title van-ellipsis" style="text-align: center;">{{ item.title }}</div>
              <div class="desc van-multi-ellipsis--l3">{{ item.subtitle }}</div>
            </div>
          </div>
        </div>
        <!-- </van-tab>
        </van-tabs> -->
      </div>
    </div>
    <div class="news main_item">
      <div class="title">
        每日资讯<span></span>
      </div>
      <div class="content">
        <van-cell-group>
          <van-cell v-for="(item, index) in newsList" :label="timeFormat(item.createtime, 'yyyy-mm-dd hh:MM')"
            :clickable="true" :key="index" size="large" @click="goArticle(item)">
            <template #title>
              <div class="van-ellipsis--l2">{{ item.title }}</div>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <Tabbar></Tabbar>
  </div>
</template>
<script>
import Tabbar from '../../components/tabbar.vue'
import { timeFormat } from '../../common/util.js'
import { Dialog, Toast } from 'vant';
export default {
  components: {
    Tabbar
  },
  data() {
    return {
      timeFormat,
      imgList: [],
      businessList: [],
      financeActive: 0,
      noticle: {},
      financeTabs: [
        {
          title: '全部',
          type: 1
        },
        {
          title: '热门',
          type: 2
        },
        {
          title: '企业贷',
          type: 3
        },
        {
          title: '发票贷',
          type: 4
        },
        {
          title: '税金贷',
          type: 5
        },
        {
          title: '保单贷',
          type: 6
        },
        {
          title: '房产信用贷',
          type: 7
        },
        {
          title: '车抵贷',
          type: 8
        }
      ],
      financeList: [
        {
          img: require('../../assets/quota/limitBg.png'),
          title: '京东金融-企业主贷',
          desc: '一次申请，双模型推荐，通过率高',
          maxQuato: '50万',
          maxTime: '12个月',
          minRate: '6.18%',
          backType: ['等额本息', '随借随还', '等额本金']
        },
        {
          img: require('../../assets/quota/limitBg.png'),
          title: '京东金融-企业主贷',
          desc: '一次申请，双模型推荐，通过率高',
          maxQuato: '50万',
          maxTime: '12个月',
          minRate: '6.18%',
          backType: ['等额本息', '随借随还', '等额本金']
        }
      ],
      lifeActive: 0,
      lifeTabs: [
        {
          title: '全部',
          type: 1
        },
        {
          title: '热门',
          type: 2
        },
        {
          title: '财税',
          type: 3
        },
        {
          title: '二手车',
          type: 4
        },
        {
          title: '二手房',
          type: 5
        }
      ],
      lifeList: [
        {
          img: require('../../assets/quota/limitBg.png'),
          title: '京东金融-企业主贷',
          desc: '一次申请，双模型推荐，通过率高'
        },
        {
          img: require('../../assets/quota/limitBg.png'),
          title: '京东金融-企业主贷',
          desc: '一次申请，双模型推荐，通过率高'
        }
      ],
      newsList: [],
      numberList: [],
      platform_services: []
    }
  },
  mounted() {
    this.getIdentity()
    this.getInit()
  },
  methods: {
    // 身份查询
    getIdentity() {
      this.$http.get('user/identity').then(res => {
        this.$store.commit('setUserIdentity', res.data)
      }).catch(err => {
        console.log(err);
      })
    },
    getInit() {
      this.$http.post('index/index', {
        limit: 4
      }).then(res => {
        this.imgList = res.data.banner
        this.newsList = res.data.news
        res.data.type.forEach(item => {
          item.title = item.name.split('-')
        })
        this.businessList = res.data.type
        this.noticle = res.data.noticle
        this.numberList = res.data.number
        this.platform_services = res.data.platform_services
        this.businessList.push({
          more: "更多业务",
          image: "https://wap.xmahx.com/uploads/20231227/e217e7395cb850363aac453f9a58a1cd.png",
          type: 'more'
        })
      }).catch(err => {
        console.log(err);
      })
    },
    getGridBusiness(e) {
      if (e.type == 'more') {
        Toast('更多产品正在开发中');
      } else {
        this.$router.push({
          name: 'Information',
          query: {
            name: e.title[1],
            type: e.keywords
          }
        })
      }

      // if (localStorage.getItem('token')) {
      //   this.$router.push({
      //     name: 'Information',
      //     query: {
      //       name: e.name,
      //       type: e.keywords
      //     }
      //   })
      // } else {
      //   Dialog.confirm({
      //     title: '温馨提示',
      //     message: '当前暂无登录，请先前往登录！',
      //   }).then(() => {
      //     this.$router.push({
      //       name: 'Login',
      //     })
      //   }).catch(() => {
      //   });
      // }
    },
    goArticle(item) {
      this.$router.push({
        name: 'Article',
        query: {
          id: item.id
        }
      })
    },
    goService(item) {
      this.$router.push({
        name: 'Service',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper {
  height: 180px;
  width: 100vw;
}

.Home {
  background-color: #F2F2F2;
  height: 100vh;
  overflow: auto;
}

.bar {
  border-radius: 10px;
  width: 95vw;
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.static {
  width: 95vw;
  margin: 15px auto 0;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;

  .num {
    // width: 90%;
    font-size: 15px;
    font-weight: 800;
    color: #0084FF;
    line-height: 24px;
    text-align: center;
    box-sizing: border-box;
  }

  .status {
    font-size: 13px;
    font-weight: 500;
    color: #848484;
    line-height: 20px;
  }
}

.main_item {
  width: 95vw;
  margin-left: 2.5vw;
  margin-top: 15px;

  .title {
    font-size: 18px;
    font-weight: 800;
    color: #383838;
    line-height: 20px;

    span {
      font-size: 12px;
      font-weight: 500;
      color: #828282;
      line-height: 20px;
      margin-left: 5px;
    }
  }
}

.tabs {
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.business {
  // ::v-deep .van-grid-item__content {
  //   // background: linear-gradient(180deg, #42A5FF 63%, #A3D7FF 100%);
  //   background-image: url('../../assets/icons/dai.png');
  //   background-size: 100%;
  //   border-radius: 10px;
  //   height: 85px;
  // }

  .content {
    background-color: #fff;
    padding: 10px 0;
    border-radius: 10px;
    margin-top: 10px;
  }

  .gridBox {
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .name {
    position: relative;
    font-size: 16px;
    font-weight: 800;
    color: #001C52;
    line-height: 24px;
    z-index: 10;
  }

  .num {
    position: relative;
    margin-top: 5px;
    font-size: 12px;
    padding: 3px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 6px 6px 6px 6px;
    color: rgba(0, 68, 131, 1);
    z-index: 10;
  }
}

.finance {
  .list {
    margin-top: 10px;
  }

  .list_item {
    display: flex;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    box-sizing: border-box;

    .pic {
      flex-shrink: 0;
      width: 40%;
      align-self: flex-start;
      border-radius: 10px;
      height: 90px;
    }

    .cont {
      width: calc(100% - 40% - 10px);
      padding-left: 10px;

      .title {
        font-size: 20px;
        font-weight: 800;
        color: #363636;
        line-height: 24px;

      }

      .desc {
        font-size: 14px;
        font-weight: 650;
        color: #787777;
        line-height: 20px;
        margin: 8px 0;
        // max-width: 200px;
      }

      .value_time {
        display: flex;
        margin-bottom: 5px;
      }

      .value,
      .time {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;

        .v {
          font-size: 16px;
          font-weight: 800;
          color: #363636;
          line-height: 20px;
        }

        .t {
          font-size: 12px;
          font-weight: 400;
          color: #000000;
          line-height: 20px;
        }
      }

      .rate {
        display: flex;
        margin-top: 2px;

        .v,
        .t {
          width: 50%;
          flex-shrink: 0;
        }

        .v {
          font-size: 14px;
          font-weight: 800;
          color: #363636;
        }

        .t {
          font-size: 12px;
          font-weight: 400;
          color: #000000;
          white-space: wrap;
        }
      }
    }
  }
}

.news {
  padding-bottom: 30px;

  .content {
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
  }
}

::v-deep .van-grid-item__content {
  padding: 16px 0;
}
</style>