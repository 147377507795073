import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import request from "./api/request";
import { Lazyload } from "vant";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

// 在 main.js 中引入
import "@/assets/css/font.css";

Vue.use(Lazyload);
Vue.use(Vant);

Vue.config.productionTip = false;

Vue.prototype.$http = request;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
