//封装post，get请求
import { request } from "./serve.js";
const jyRquest = {
  //  post 封装请求
  post(url, data, headers, responseType) {
    return request({ url, data, headers, responseType, method: "POST" });
  },
  // get 封装请求
  get(url, params, headers, responseType) {
    return request({ url, params, headers, responseType, method: "GET" });
  },
};
export default jyRquest;
